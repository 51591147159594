const initialState = {
  userProjects: [],
  userInfo: {},
  error: "",
  addingUserDiscount: false,
  updatingUserDiscount: false,
  userDiscount: null,
  fetchingUserDiscount: false
  // projectApps: [],
  // fetchAppsError: ''
};

export default initialState;
