const initialState = {
  createClusterCallStarted: false,
  createCluserCallSuccess: false,
  createClusterCallFailure: false,
  editClusterCallStarted: false,
  editCluserCallSuccess: false,
  editClusterCallFailure: false,
  error: "",
  regions: [],
};

export default initialState;
