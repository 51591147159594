const initialState = {
  createPluginCallStarted: false,
  createPluginCallSuccess: false,
  createPluginCallFailure: false,
  editPluginCallStarted: false,
  editPluginCallSuccess: false,
  editPluginCallFailure: false,
};

export default initialState;
