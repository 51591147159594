const USER_INFO_PREFIX = "@USER_INFO";
const USER_UPDATE_PREFIX = "@USER_UPDATE";
const USER_INSIGHTS = "@USER_INSIGHTS";
// const PROJECT_APP_PREFIX = "@PROJECT_APPS";

export const FETCH_USER_INFO = `${USER_INFO_PREFIX}/FETCH_USER_INFO`;
export const FETCH_USER_INFO_SUCCESS = `${USER_INFO_PREFIX}/FETCH_USER_INFO_SUCCESS`;
export const FETCH_USER_INFO_FAILURE = `${USER_INFO_PREFIX}/FETCH_USER_INFO_FAILURE`;
export const FETCH_USER_PROJECTS = `${USER_INFO_PREFIX}/FETCH_USER_INFO_FAILURE`;
export const FETCH_USER_PROJECTS_SUCCESS = `${USER_INFO_PREFIX}/FETCH_USER_PROJECTS_SUCCESS`;
export const FETCH_USER_PROJECTS_FAILURE = `${USER_INFO_PREFIX}/FETCH_USER_PROJECTS_FAILURE`;
export const UPDATE_USER_ACTIVE_STATUS = `${USER_UPDATE_PREFIX}/UPDATE_ACTIVE_STATUS`;
export const UPDATE_USER_ACTIVE_STATUS_SUCCESS = `${USER_UPDATE_PREFIX}/UPDATE_ACTIVE_STATUS_SUCCESS`;
export const UPDATE_USER_ACTIVE_STATUS_FAILURE = `${USER_UPDATE_PREFIX}/UPDATE_ACTIVE_STATUS_FAILURE`;
export const UPDATE_USER_ADMIN_STATUS = `${USER_UPDATE_PREFIX}/UPDATE_ADMIN_STATUS`;
export const UPDATE_USER_ADMIN_STATUS_SUCCESS = `${USER_UPDATE_PREFIX}/UPDATE_ADMIN_STATUS_SUCCESS`;
export const UPDATE_USER_ADMIN_STATUS_FAILURE = `${USER_UPDATE_PREFIX}/UPDATE_ADMIN_STATUS_FAILURE`;
export const FETCH_USER_INSIGHTS = `${USER_INSIGHTS}/FETCH`;
export const FETCH_USER_INSIGHTS_SUCCESS = `${USER_INSIGHTS}/FETCH_SUCCESS`;
export const FETCH_USER_INSIGHTS_FAILURE = `${USER_INSIGHTS}/FETCH_FAILURE`;
// export const FETCH_PROJECT_APPS = `${PROJECT_APP_PREFIX}/FETCH_PROJECT_APP`;
// export const FETCH_PROJECT_APPS_SUCCESS = `${PROJECT_APP_PREFIX}/FETCH_PROJECT_APP_SUCCESS`;
// export const FETCH_PROJECT_APPS_FAILURE = `${PROJECT_APP_PREFIX}/FETCH_PROJECT_APP_FAILURE`;

export const ADD_USER_DISCOUNT = `${USER_INSIGHTS}/ADD_USER_DISCOUNT`;
export const ADD_USER_DISCOUNT_SUCCESS = `${USER_INSIGHTS}/ADD_USER_DISCOUNT_SUCCESS`;
export const ADD_USER_DISCOUNT_FAILURE = `${USER_INSIGHTS}/ADD_USER_DISCOUNT_FAILURE`;

export const UPDATE_USER_DISCOUNT = `${USER_INSIGHTS}/UPDATE_USER_DISCOUNT`;
export const UPDATE_USER_DISCOUNT_SUCCESS = `${USER_INSIGHTS}/UPDATE_USER_DISCOUNT_SUCCESS`;
export const UPDATE_USER_DISCOUNT_FAILURE = `${USER_INSIGHTS}/UPDATE_USER_DISCOUNT_FAILURE`;

export const GET_USER_DISCOUNT = `${USER_INSIGHTS}/GET_USER_DISCOUNT`;
export const GET_USER_DISCOUNT_SUCCESS = `${USER_INSIGHTS}/GET_USER_DISCOUNT_SUCCESS`;
export const GET_USER_DISCOUNT_FAILURE = `${USER_INSIGHTS}/GET_USER_DISCOUNT_FAILURE`;

export const CLEAR_USER_DISCOUNT_DATA = `${USER_INSIGHTS}/CLEAR_USER_DISCOUNT_DATA`;

export const clearDiscountData = () => ({
	type: CLEAR_USER_DISCOUNT_DATA
})

export const addUserDiscount = (jsonBody) => ({
	type: ADD_USER_DISCOUNT,
	data: { jsonBody }
})

export const updateUserDiscount = (id, jsonBody) => ({
	type: UPDATE_USER_DISCOUNT,
	data: { id, jsonBody }
})

export const getUserDiscount = (id) => ({
  type: GET_USER_DISCOUNT,
  id: id
})

const fetchUserInfo = (userId) => {
  return {
    type: FETCH_USER_INFO,
    id: userId,
  };
};
const fetchUserProjects = (userId) => {
  return {
    type: FETCH_USER_PROJECTS,
    id: userId,
  };
};

const updateUserActiveStatus = (userData) => ({
  type: UPDATE_USER_ACTIVE_STATUS,
  data: userData,
});
const updateUserAdminStatus = (userData) => ({
  type: UPDATE_USER_ADMIN_STATUS,
  data: userData,
});

// const fetchProjectApps = (projectId) => {
//   return {
//     type: FETCH_PROJECT_APPS,
//     data: {
//       projectId,
//     },
//   };
// };

export {
  fetchUserInfo,
  updateUserActiveStatus,
  updateUserAdminStatus,
  fetchUserProjects,
  // fetchProjectApps,
};
