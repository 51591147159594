const OPERATORS_PREFIX = "@OPERATORS";

export const GET_OPERATORS = `${OPERATORS_PREFIX}/GET_OPERATORS`;
export const GET_OPERATORS_SUCCESS = `${OPERATORS_PREFIX}/GET_OPERATORS_SUCCESS`;
export const GET_OPERATORS_FAILURE = `${OPERATORS_PREFIX}/GET_OPERATORS_FAILURE`;

export const UPDATE_OPERATOR_STATUS = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_STATUS`;
export const UPDATE_OPERATOR_STATUS_SUCCESS = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_STATUS_SUCCESS`;
export const UPDATE_OPERATOR_STATUS_FAILURE = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_STATUS_FAILURE`;

export const SYNC_OPERATOR = `${OPERATORS_PREFIX}/SYNC_OPERATOR`;
export const SYNC_OPERATOR_SUCCESS = `${OPERATORS_PREFIX}/SYNC_OPERATOR_SUCCESS`;
export const SYNC_OPERATOR_FAILURE = `${OPERATORS_PREFIX}/SYNC_OPERATOR_FAILURE`;

export const FETCH_OPERATOR_DETAILS = `${OPERATORS_PREFIX}/FETCH_OPERATOR_DETAILS`;
export const FETCH_OPERATOR_DETAILS_SUCCESS = `${OPERATORS_PREFIX}/FETCH_OPERATOR_DETAILS_SUCCESS`;
export const FETCH_OPERATOR_DETAILS_FAILURE = `${OPERATORS_PREFIX}/FETCH_OPERATOR_DETAILS_FAILURE`;
export const CLEAR_OPERATOR_DETAILS = `${OPERATORS_PREFIX}/CLEAR_OPERATOR_DETAILS`;

export const UPDATE_OPERATOR_DETAILS = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_DETAILS`;
export const UPDATE_OPERATOR_DETAILS_SUCCESS = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_DETAILS_SUCCESS`;
export const UPDATE_OPERATOR_DETAILS_FAILURE = `${OPERATORS_PREFIX}/UPDATE_OPERATOR_DETAILS_FAILURE`;

export const getOperators = () => ({
  type: GET_OPERATORS,
});

export const updateOperatorStatus = (packageName, isActive) => ({
  type: UPDATE_OPERATOR_STATUS,
  packageName,
  isActive,
});

export const syncOperator = packageName => ({
  type: SYNC_OPERATOR,
  packageName,
});

export const fetchOperatorDetails = packageName => ({
  type: FETCH_OPERATOR_DETAILS,
  packageName,
});

export const clearOperatorDetails = () => ({ type: CLEAR_OPERATOR_DETAILS });

export const updateOperatorDetails = (packageName, jsonPayload) => ({
  type: UPDATE_OPERATOR_DETAILS,
  packageName,
  jsonPayload,
});
