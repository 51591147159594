const initialState = {
    uploadingFile: false,
    nav: {
        navText: null,
        toLink: null
    },
        //quota config states
    fetchingQuotaConfig:false,
    quotaConfig:null,
    quotaConfigError:null,

    // update quota configs
    isUpdatingQuataConfig:false,
    // updatedQuotaConfig:null,
    updateQuotaConfigError:null,
}

export default initialState