export const deletePromo=(promoCodeList,promoId)=>{

const promoIndex=promoCodeList.findIndex((promoCode)=>promoCode.id===promoId)
if(promoIndex<0){
    return promoCodeList
    // no mutation, if promo not found
}
promoCodeList.splice(promoIndex,1);

    return [...promoCodeList]
}