export const getNewUserList = (userList, userData) => {
  const userId = userData.id;
  const userListIndex = userList.findIndex((user) => user.id === userId);
  console.log("userListIndex", userListIndex);
  if (userListIndex < 0) {
    // returning without spread...(no mutation in store)
    return userList;
  }
  userList[userListIndex] = userData;
  return [...userList];
};
