const initialState = {
  resourceList: [],
  error: "",
  resource: {},
  deleteResourceStarted: false,
  deleteResourceSuccess: false,
  deleteResourceFailure: false,
};

export default initialState;
