const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    signupComplete: false,
    isFetching: false,
    sessionToken: '',
    token:'',
    user: null
}

export default initialState;