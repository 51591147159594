const initialState = {
  userList: [],
  error: "",
  userCount: 0,
  fetchingUser: false,

  // update user quota
  isUpdatingUserQuota:false,
  userQuotaUpdated:false,
  userQuotaError:null,
};

export default initialState;
