const initialState = {
  createResourceCallStarted: false,
  createResourceCallSuccess: false,
  createResourceCallFailure: false,
  editResourceCallStarted: false,
  editResourceCallSuccess: false,
  editResourceCallFailure: false,
  error: "",
};

export default initialState;
