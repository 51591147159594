import React from 'react';
//import TextField from '@material-ui/core/TextField';
import './companybanner.css';
import Grid from '@material-ui/core/Grid';

const ComapnyBanner = () => {
  return <Grid item xs={false} sm={4} md={6} className='image' />
};

export default ComapnyBanner;
