const initialState = {
  pluginList: [],
  error: "",
  pluginCount: 0,
  fetchingPlugin: false,
  deletePluginStarted: false,
  deletePluginSuccess: false,
  deletePluginFailure: false,
  categories: [],
  fetchingCategory: false,
};
export default initialState;
