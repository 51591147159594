const initialState = {
  createSubscriptionCallStarted: false,
  createSubscriptionCallSuccess: false,
  createSubscriptionCallFailure: false,
  editSubscriptionCallStarted: false,
  editSubscriptionCallSuccess: false,
  editSubscriptionCallFailure: false,
  error: "",
};

export default initialState;
