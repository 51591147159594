const initialState = {
  fetchingRegistries: false,
  registrylist: null,
  registryDetails: null,
  addingRegistry: false,
  updatingRegistry: false,
  deletingRegistry: false,
  registryConfig: null,
  registryconfigLoading: false,
};

export default initialState;
